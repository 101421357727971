<template>
  <el-main>
    <div class="btn">
      <router-link to="/extension/cardManage/cardAdd"><el-button type="primary" class="el-icon-plus" size="small">添加名片</el-button></router-link>
    </div>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="姓名：">
        <el-input size="small" v-model="form.first_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="电话号码：">
        <el-input size="small" v-model="form.mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="添加时间：">
        <el-date-picker v-model="form.create_time" size="small" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="21px">
        <el-button size="small" type="primary" @click="getCardList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
      <el-table-column prop="type" label="头像" min-width="100" align="center">
        <template v-slot="{ row }">
          <el-image :src="row.thumb" style="border-radius: 50%; width: 60px"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="first_name" min-width="100" label="姓名" align="center"></el-table-column>
      <el-table-column prop="mobile_show" label="手机号" min-width="150" align="center"></el-table-column>
      <el-table-column prop="view" label="人气" min-width="100" align="center"></el-table-column>
      <el-table-column prop="like" label="点赞" min-width="100" align="center"></el-table-column>
      <el-table-column prop="relay" label="转发" min-width="100" align="center"></el-table-column>
      <el-table-column label="操作" align="center" min-width="200">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="$router.push({path:'/extension/cardManage/cardEdit',query:{id:row.id}})">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(row.id)">删除</el-button>
          <el-button type="text" size="small" @click="$router.push({path:'/extension/cardManage/cardImpression',query:{id:row.id}})">印象管理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      form: {
        page: 1,
        rows: 10,
        first_name: '',
        mobile: '',
        create_time: '',
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.getCardList();
  },
  methods: {
    getCardList(page = 0) {
      if (page) {
        this.form.page = 1;
      }
      let obj = { ...this.form };
      if (obj.create_time) {
        obj.create_time = obj.create_time / 1000;
      }
      this.$axios.post(this.$api.cardManage.cardList, this.form).then(res => {
        if (res.code == 0) {
          this.total = res.result.total_number;
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getCardList();
      } else {
        this.form.page = val;
        this.getCardList();
      }
    },
    handleDelete(id){
      this.$confirm(`确定要删除此项吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$api.cardManage.deleteCard, {
              id: id
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success('删除成功')
                this.getCardList(1)
              }
            })
        })
        .catch(() => {})
    }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
